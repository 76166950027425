<template>
    <div class="Release_share">
        <div class="bg">
            <VanImage width="100%" height="100%" lazy-load :src="require('@assets/images/test/test02.jpg')" />
        </div>
        分享给好友
        
        <div class="hd">
            <span class="busername Ellipses1">大圣999</span>
            <span class="grade bg-color">酒徒</span>
            <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
            <span class="font-color">酒量值: 220</span>
        </div>
    </div>
</template>
<script>
import { Image as VanImage } from 'vant';
export default {
    name: "Release_share",
    data: function() {
        return {
            
        };
    },
    components: {
        VanImage
    },
    created: function() {
        
    },
    mounted: function() {
        
    },
    methods: {
        
    }
};
</script>
<style scoped lang="scss">
.Release_share{
    height: 100%;position: relative;
    .bg{
        filter:blur(0.1rem);position: fixed;top: 0;left: 0;right: 0;bottom: 0;
    }
    .hd{
        margin-top:0.2rem;overflow:hidden;
        span{margin-right:0.2rem;display:block;float:left;}
        .busername{max-width: 2rem;}
        .grade{color:#fff;font-size:0.24rem;padding:0 0.2rem;line-height:0.4rem;border-radius:0.1rem;}
        .van-image{float:left;margin-right:0.1rem;}
    }
}

            
</style>